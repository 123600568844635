html {
  font-size     : 12pt;
  & * {
    margin      : 0;
    padding     : 0;
    box-sizing  : border-box;
    font-family : Montserrat;
  }
}    

 body:after {
  content: 
    url('../images/tl.jpg')
    url('../images/bed01.jpg')
    url('../images/fed01.jpg')
    url('../images/pm.jpg')
    url('../images/fed02.jpg')
    url('../images/bed02.jpg');
    display: none;
 }

//@import '../blocks.default/transformed/transformed.scss';

@import '../blocks.default/wrapper/wrapper.scss';

@import '../blocks.default/text/text.scss';
@import '../blocks.default/text/_uppercased/text_uppercased.scss';
@import '../blocks.default/text/_main-heading/text_main-heading.scss';
@import '../blocks.default/text/_main-heading/_uppercased/text_main-heading_uppercased.scss';
@import '../blocks.default/text/_second-heading/text_second-heading.scss';
@import '../blocks.default/text/_third-heading/text_third-heading.scss';
@import '../blocks.default/text/_third-heading/_underlined/text_third-heading_underlined.scss';
@import '../blocks.default/text/_mini/text_mini.scss';
@import '../blocks.default/text/_micro/text_micro.scss';
@import '../blocks.default/text/_big/text_big.scss';

@import '../blocks.default/header/header.scss';
@import '../blocks.default/header/__logo/header__logo.scss';
@import '../blocks.default/header/__element/header__element.scss';

@import '../blocks.default/nav-menu/nav-menu.scss';
@import '../blocks.default/nav-menu/__item/nav-menu__item.scss';
@import '../blocks.default/nav-menu/__link/nav-menu__link.scss';
@import '../blocks.default/nav-menu/__link/_active/nav-menu__link_active.scss';
@import '../blocks.default/nav-menu/__menu-button/nav-menu__menu-button.scss';

@import '../blocks.default/vertical-menu/vertical-menu.scss';
@import '../blocks.default/vertical-menu/__item/vertical-menu__item.scss';
@import '../blocks.default/vertical-menu/__link/vertical-menu__link.scss';

@import '../blocks.default/row/row.scss';
@import '../blocks.default/row/_fullsize/row_fullsize.scss';
@import '../blocks.default/row/_theme/row_theme-white.scss';
@import '../blocks.default/row/__header/row__header.scss';
@import '../blocks.default/row/__inner/row__inner.scss';
@import '../blocks.default/row/__inner/_flex/row__inner_flex.scss';

@import '../blocks.default/mosaic/mosaic.scss';
@import '../blocks.default/mosaic/__item/mosaic__item.scss';

@import '../blocks.default/img-switch/img-switch.scss';

@import '../blocks.default/tile/tile.scss';
@import '../blocks.default/tile/_100perc/tile_100perc.scss';
@import '../blocks.default/tile/_switch-colors/tile_switch-colors.scss';
@import '../blocks.default/tile/_switch-colors/tile_switch-colors-rev.scss';
@import '../blocks.default/tile/__header/tile__header.scss';
@import '../blocks.default/tile/__body/tile__body.scss';
@import '../blocks.default/tile/__body/_dark/tile__body_dark.scss';
@import '../blocks.default/tile/__body/_green/tile__body_green.scss';
@import '../blocks.default/tile/__footer/tile__footer.scss';
@import '../blocks.default/tile/__body/_transparent/tile__body_transparent.scss';
@import '../blocks.default/tile/__link/tile__link.scss';
@import '../blocks.default/tile/__corner-element/tile__corner-element.scss';
@import '../blocks.default/tile/js_hidden-el/js_hidden-el.scss';

@import '../blocks.default/vertical-icons-list/vertical-icons-list.scss';
@import '../blocks.default/vertical-icons-list/__item/vertical-icons-list__item.scss';
@import '../blocks.default/vertical-icons-list/__link/vertical-icons-list__link.scss';
@import '../blocks.default/vertical-icons-list/__link/_white/vertical-icons-list__link_white.scss';
@import '../blocks.default/vertical-icons-list/__svg/vertical-icons-list__svg.scss';
@import '../blocks.default/vertical-icons-list/__svg/_white/vertical-icons-list__svg_white.scss';

@import '../blocks.default/list/list.scss';
@import '../blocks.default/list/_no-marker/list_no-marker.scss';

@import '../blocks.default/icons/icons.scss';
@import '../blocks.default/icons/__item/icons__item.scss';
@import '../blocks.default/icons/__svg/icons__svg.scss';

@import '../blocks.default/contact-icons/contact-icons.scss';
@import '../blocks.default/contact-icons/_vertical/contact-icons_vertical.scss';
@import '../blocks.default/contact-icons/__item/contact-icons__item.scss';
@import '../blocks.default/contact-icons/__svg/contact-icons__svg.scss';
@import '../blocks.default/contact-icons/__svg/_with-border/contact-icons__svg_with-border.scss';

@import '../blocks.default/tools-list/__item/tools-list__item.scss';

@import '../blocks.default/footer-inner/footer-inner.scss';

@import '../blocks.default/background-imgs/works-img.scss';

@import '../blocks.default/mobile-toggle/mobile-toggle.scss';

@import '../blocks.default/_no-select/_no-select.scss';

@import '../blocks.default/_with-title/_with-title.scss';

@media (max-width: 1200px) {
  html {
    font-size: 10pt;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 8pt;
  }
}


